<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy" class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <!-- a class="footer-link-last" title="TikTok tts Pricing" :href="pricing_href" rel="nofollow">
          {{ $t('comm.pricing') }}
        </a -->
        <a class="footer-link-last" href="mailto:support@tiktokvoice.net">{{ $t('comm.footer_help') }} support@tiktokvoice.net</a>
        <a :href="linksPageUrl" target="_blank" class="footer-link-last">Links</a>
      </div>
    </div>

    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>

  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data() {
    return {
      pricing_href: '/en/pricing',
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    },
    linksPageUrl() {
      return `/en/friends`
    }
  },
  mounted() {
    let lang = this.$store.state.lang
    this.pricing_href = `/${lang}/pricing`
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.friend-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.friend-link {
  margin: 0 0.5rem;
  color: #fff;
  text-decoration: none;
}

.show-links-btn {
  padding: 8px 16px;
  background-color: #666;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  display: inline-block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.friend-link {
  color: #666;
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
